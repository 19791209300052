
import {Line} from 'vue-chartjs';
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';

@Component({
    extends: Line,
})
export default class DashboardChartComponent extends mixins(Line) {

    @Prop({default: () => []})
    public chartData: any;

    private mounted() {
        const monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
            'October', 'November', 'December'];

        this.renderChart({
            labels: monthArray.map((month: string) => this.$t(`GENERAL.MONTH.${month.toUpperCase()}`).toString()),
            datasets: [
                {
                    data: this.chartData,
                    borderWidth: 2,
                    backgroundColor: this.fillColor,
                    borderColor: this.strokeColor,
                },
            ],
        }, {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
        });
    }

    get fillColor(): string {
        return this.$colorHandler.getThemeColor('chart-fill-color');
    }

    get strokeColor(): string {
        return this.$colorHandler.getThemeColor('chart-stroke-color');
    }
}
